const tendonGlide = {
  id: "tendon-glide",
  title: "Tendon Glide",
  author: "Ability Rehabilitation",
  description: "Tendon glide exercises are a series of hand and wrist movements that improve the flow of the tendon through the carpal tunnel.",
  video: "https://www.youtube.com/watch?v=favZefGkiHk",
  routine: [
    {
      "type": "finger",
      "name": "Duck",
      "sets": 1,
      "rest": 3,
      "amount": 10,
      "units": "reps",
    },
    {
      "type": "finger",
      "name": "Hook",
      "sets": 1,
      "rest": 3,
      "amount": 10,
      "units": "reps",
    },
    {
      "type": "finger",
      "name": "Squeeze",
      "sets": 1,
      "rest": 3,
      "amount": 10,
      "units": "reps",
    },
    {
      "type": "finger",
      "name": "Touch and Trace",
      "sets": 1,
      "rest": 3,
      "amount": 10,
      "units": "reps",
    },
    {
      "type": "finger",
      "name": "Spread Wide",
      "sets": 1,
      "rest": 3,
      "amount": 10,
      "units": "reps",
    },
  ]
}

export { tendonGlide };