const hangboard = {
  id: "hangboard",
  title: "Hangboard Conditioning",
  author: "Emil Abrahamsson",
  description: "Load fingers sub-maximally for tendon, skin, and neuromuscular conditioning.",
  video: "https://www.youtube.com/watch?v=3FNZdixeuZw",
  routine: [
    {
      "type": "finger",
      "name": "Half Crimp",
      "sets": 6,
      "rest": 20,
      "amount": 10,
      "units": "s",
    },
    {
      "type": "finger",
      "name": "3-finger Drag",
      "sets": 6,
      "rest": 20,
      "amount": 10,
      "units": "s"
    },
    {
      "type": "finger",
      "name": "Front 2-finger Drag",
      "sets": 2,
      "rest": 20,
      "amount": 10,
      "units": "s"
    },
    {
      "type": "finger",
      "name": "Middle 2-finger Drag",
      "sets": 2,
      "rest": 20,
      "amount": 10,
      "units": "s"
    },
    {
      "type": "finger",
      "name": "Front 2-finger Crimp",
      "sets": 2,
      "rest": 20,
      "amount": 10,
      "units": "s"
    },
    {
      "type": "finger",
      "name": "Middle 2-finger Crimp",
      "sets": 2,
      "rest": 20,
      "amount": 10,
      "units": "s"
    },
  ]
}

export { hangboard };