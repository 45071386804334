function sequenceRoutine(routine) {
  let sequence = []

  for (let i = 0; i < routine.length; i++) {
    for (let j = 0; j < routine[i].sets; j++) {
      if (routine[i].units != "reps") {
        const set = {
          "name": routine[i].name,
          "type": routine[i].type,
          "units": routine[i].units,
          "amount": routine[i].amount,
          "mode": "set",
          "current": j + 1,
          "left": routine[i].sets - j,
          "total": routine[i].sets,
          "next": ((i < routine.length - 1) && (j == routine[i].sets - 1)) ? routine[i + 1].name : "",
        }
        sequence.push(set);
      }

      const rest = {
        "name": routine[i].name,
        "type": routine[i].type,
        "units": routine[i].units,
        "amount": routine[i].rest,
        "mode": "rest",
        "current": j + 1,
        "left": routine[i].sets - j - 1,
        "total": routine[i].sets,
        "next": ((i < routine.length - 1) && (j == routine[i].sets - 1)) ? routine[i + 1].name : "",
        "pause": (routine[i].units == "reps") ? true : false,
        "reps": (routine[i].units == "reps") ? routine[i].amount : null,
      }
      sequence.push(rest);
    }
  }

  return sequence;
}

export { sequenceRoutine };