import { hangboard } from './exercises/hangboard.js'
import { forearm } from './exercises/forearm.js'
import { tendonGlide } from './exercises/tendon-glide.js'
import { rotatorCuffWarmup } from './exercises/rotator-cuff-warmup.js'
import { Timer } from './components/timer.js'
import { sequenceRoutine } from './components/routine.js'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(new URL('/service-worker.js', import.meta.url))
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    }
    );
}


const routineMap = {
  "hangboard": hangboard,
  "forearm": forearm,
  "tendon-glide": tendonGlide,
  "rotator-cuff-warmup": rotatorCuffWarmup
}

const loadRoutineDetails = (routineObj) => {
  let estimated_time = 0;
  routineObj.routine.forEach((exercise) => {
    const defaultExerciseDuration = 2;
    const duration = (exercise.units == "reps") ? (defaultExerciseDuration * exercise.amount) : exercise.amount;
    estimated_time += exercise.sets * (duration + exercise.rest);
  });
  estimated_time = Math.round(estimated_time / 60);

  let exerciseList = ["<ul>"];
  exerciseList.push(parseExercises(routineObj.routine))
  exerciseList.push("</ul>");

  document.getElementById("author").innerHTML = routineObj.author;
  document.getElementById("title").innerHTML = routineObj.title;
  document.getElementById("description").innerHTML = routineObj.description;
  document.getElementById("estimatedTime").innerHTML = estimated_time;
  document.getElementById("exerciseList").innerHTML = exerciseList.join("");

  window.timer.init(sequenceRoutine(routineObj.routine));
};

const parseExercises = (routine) => {
  let description = [];

  routine.forEach((exercise) => {
    if (!("name" in exercise)) {
      return;
    }
    description.push("<li>")
    description.push("<strong>" + exercise.name + "</strong>");
    if ("sets" in exercise && exercise.sets > 0) {
      description.push(" - " + exercise.sets + " sets");
    }
    if ("amount" in exercise && exercise.amount > 0) {
      description.push(" x " + exercise.amount);
    }
    if ("units" in exercise) {
      description.push(" " + exercise.units);
    }
    description.push("</li>");
  })

  return description.join("");
}

const loadRoutine = (routineName) => {
  const routine = routineMap[routineName] || routineMap["hangboard"];  // Fallback to hangboard if routineName doesn't exist
  loadRoutineDetails(routine);

  // Scroll to training view
  var trainingView = document.getElementById("training");
  trainingView.scrollIntoView();
}

window.timer = Timer.getInstance();
window.loadRoutine = loadRoutine;
