// TODO: typescript and proper class
const Timer = (function () {
  let instance;

  function Timer() {
    this.schedule = [];
    this.scheduleIndex = 0;

    this.clock = document.getElementById('clock');
    this.clockStartButton = document.getElementById('clockStartButton');
    this.clockPauseButton = document.getElementById('clockPauseButton');

    this.clockRoutineType = document.getElementById('clockRoutineType');
    this.clockExerciseName = document.getElementById('clockExerciseName');
    this.clockNextExerciseName = document.getElementById('clockNextExerciseName');

    this.clockRepsCounter = document.getElementById('clockRepsCounter');
    this.clockExerciseMode = document.getElementById('clockExerciseMode');
    this.clockSetsCurrent = document.getElementById('clockSetsCurrent');
    this.clockSetsTotal = document.getElementById('clockSetsTotal');

    this.clockMilliseconds = 0;
    this.clockSecondsCounter = 10;
    this.clockMinutesCounter = 0;
    this.clockInterval;

    // TODO: Enable initializing different status (countdown, go, running, done)
    // Set timer schedule
    this.init = (newSchedule) => {
      this.schedule = newSchedule;
      this.scheduleIndex = 0;
      //TODO: EXTRACT TO OTHER FUNCTION
      this.clockRoutineType.innerHTML = this.schedule[0].type;

      this.clockExerciseName.innerHTML = this.schedule[0].name;

      this.clockNextExerciseName.innerHTML = (this.schedule[0].next) ? ("Next: " + this.schedule[0].next) : "";

      this.clockRepsCounter.innerHTML = ((this.schedule[0].units == "reps") && ("reps" in this.schedule[0])) ? this.schedule[0].reps + " reps" : "";

      this.clockExerciseMode.innerHTML = this.capitalize(this.schedule[0].mode);
      this.clockSetsCurrent.innerHTML = this.schedule[0].current;
      this.clockSetsTotal.innerHTML = this.schedule[0].total;

      this.clockSecondsCounter = 3; //Countdown init ?
      this.clockMilliseconds = 0;

      this.clockStartButton.classList.remove("hidden");
      this.clockPauseButton.classList.add("hidden");
      this.status = "countdown"
      this.setClock("Ready?");
    };

    // TODO: do something about this, it fucking sucks
    this.start = () => {
      if (this.clockInterval) {
        clearInterval(this.clockInterval);
      }

      this.clockInterval = setInterval(() => {
        switch (this.status) {
          // TODO: Conditional countdown
          case "countdown": {
            // console.log("countdown")
            if (this.clockMilliseconds <= 0) {
              this.clockMilliseconds = 99;
              this.setClock(this.clockSecondsCounter);
              this.clockSecondsCounter--;
              if (this.clockSecondsCounter === -1) {
                this.setClock("Go!");
                this.clockSecondsCounter = 1;
                this.status = "go";
              }
            }
            this.clockMilliseconds--;
            break;
          }
          // TODO: Conditional go
          case "go": {
            // console.log("go");
            if (this.clockMilliseconds <= 0) {
              this.clockMilliseconds = 99;
              this.clockSecondsCounter--;
              if (this.clockSecondsCounter == 0) {
                this.clockSecondsCounter = this.schedule[this.scheduleIndex].amount;
                this.clockMilliseconds = 0
                this.status = "running";
              }
            }
            this.clockMilliseconds--;
            break;
          }
          case "running": {
            // console.log("running")
            if (this.clockMilliseconds <= 0) {
              this.clockMilliseconds = 99;
              this.clockSecondsCounter--;
              if (this.clockSecondsCounter === -1) {
                this.scheduleIndex++;
                if (this.scheduleIndex < this.schedule.length) {
                  // Move to the next item in the schedule
                  //EXTRACT TO OTHER FUNCTION
                  this.clockSecondsCounter = this.schedule[this.scheduleIndex].amount - 1;
                  this.clockRoutineType.innerHTML = this.schedule[this.scheduleIndex].type;

                  this.clockExerciseName.innerHTML = this.schedule[this.scheduleIndex].name;
                  this.clockNextExerciseName.innerHTML = (this.schedule[this.scheduleIndex].next) ? ("Next: " + this.schedule[this.scheduleIndex].next) : "";
                  this.clockRepsCounter.innerHTML = ((this.schedule[this.scheduleIndex].units == "reps") && ("reps" in this.schedule[this.scheduleIndex])) ? this.schedule[this.scheduleIndex].reps + " reps" : "";

                  this.clockExerciseMode.innerHTML = this.capitalize(this.schedule[this.scheduleIndex].mode);
                  this.clockSetsCurrent.innerHTML = this.schedule[this.scheduleIndex].current;
                  this.clockSetsTotal.innerHTML = this.schedule[this.scheduleIndex].total;

                  if (this.schedule[this.scheduleIndex].pause) {
                    this.stop();
                    this.clockMilliseconds = 1; // Weird fix whatever
                    this.clockSecondsCounter = this.schedule[this.scheduleIndex].amount;
                  }
                } else {
                  // All items in the schedule are completed
                  this.stop();
                  this.done();
                  return;
                }
              }
            }
            this.clockMilliseconds--;
            this.setTime(this.clockSecondsCounter, this.clockMilliseconds);
            break;
          }
          case "done":
            // TODO: Add done state
            break;
        }
      }, 10);

      this.clockStartButton.classList.add("hidden");
      this.clockPauseButton.classList.remove("hidden");
    }

    this.stop = () => {
      clearInterval(this.clockInterval);
      this.clockStartButton.classList.remove("hidden");
      this.clockPauseButton.classList.add("hidden");
    }

    this.reset = () => {
      clearInterval(this.clockInterval);
      this.init(this.schedule);
      this.clockStartButton.classList.remove("hidden");
      this.clockPauseButton.classList.add("hidden");
    }

    this.setTime = (seconds, milliseconds) => {
      this.setClock(`${seconds.toString().padStart(2, '0')}:${milliseconds.toString().padStart(2, '0')}`)
    }

    this.setClock = (value) => {
      this.clock.innerHTML = value;
    }

    this.done = () => {
      this.scheduleIndex = 0;
      this.clock.innerHTML = "Done!";
      this.clockStartButton.classList.add("hidden");
      this.clockPauseButton.classList.add("hidden");
    }

    this.capitalize = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }

  return {
    getInstance: () => {
      if (!instance) {
        instance = new Timer();
      }
      return instance;
    }
  };
})();

export { Timer };