const rotatorCuffWarmup = {
  id: "rotator-cuff-warmup",
  title: "Rotator Cuff Warmup",
  description: "Mobilize and warmup the rotator cuff muscles to prevent injury.",
  video: "https://www.youtube.com/watch?v=3FNZdixeuZw",
  routine: [
    {
      "name": "Side 90 Degree Rotation",
      "type": "none",
      "sets": 1,
      "rest": 5,
      "amount": 15,
      "units": "reps"
    },
    {
      "name": "Angled 90 Degree Rotation",
      "type": "none",
      "sets": 1,
      "rest": 5,
      "amount": 15,
      "units": "reps"
    },
    {
      "name": "Front 90 Degree Rotation",
      "type": "none",
      "sets": 1,
      "rest": 5,
      "amount": 15,
      "units": "reps"
    }
  ]
}

export { rotatorCuffWarmup };