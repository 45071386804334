const forearm = {
  id: "forearm",
  title: "Forearm Antagonists",
  description: "Train Extensor and Flexor muscles to prevent injury.",
  video: "https://www.youtube.com/watch?v=3FNZdixeuZw",
  author: "",
  routine: [
    {
      "name": "Flexor Curl",
      "type": "forearm",
      "sets": 3,
      "amount": 15,
      "units": "reps",
      "rest": 60,
    },
    {
      "name": "Extensor Curl",
      "type": "forearm",
      "sets": 3,
      "amount": 15,
      "units": "reps",
      "rest": 60,
    }
  ]
}

export { forearm };